import { themeColors } from './themes/colors';
import { CourseStatus } from './types/global';
import dataTestIds from './constants/DataTestIds';

export const APP_ENV = process.env.REACT_APP_ENV || 'development';
export const APP_GTM = process.env.REACT_APP_GTM || '';
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_USER_POOLID = process.env.REACT_APP_AWS_USER_POOLID;
export const AWS_USER_POOL_WEB_CLIENTID =
  process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENTID;

export const PARTNER_BASE_URL = process.env.REACT_APP_PARTNER_BASE_URL || '';
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
export const TAKE_SURVEY_URL = process.env.REACT_APP_TAKE_SURVEY_URL || '';
export const SUPPORT_REQUESTS_ACTION =
  process.env.REACT_APP_SUPPORT_REQUESTS_ACTION || '';
export const SUPPORT_REQUESTS_ORG_ID =
  process.env.REACT_APP_SUPPORT_REQUESTS_ORG_ID || '';
export const SUPPORT_REQUESTS_LAST_NAME_ID =
  process.env.REACT_APP_SUPPORT_REQUESTS_LAST_NAME_ID || '';
export const TRANSCRIPT_REQUEST_URL =
  process.env.REACT_APP_TRANSCRIPT_REQUEST_URL || '';
export const Record_Type = process.env.REACT_APP_Record_Type || '';
export const PROCTOR_U_BASE_URL =
  process.env.REACT_APP_PROCTOR_U_BASE_URL || '';
export const MOODLE_BASE_URL = process.env.REACT_APP_MOODLE_BASE_URL || '';
export const CDN_URL = process.env.REACT_APP_CDN_URL || '';
export const BC_API_URL = process.env.REACT_APP_BC_API_URL || '';
export const BC_URL = process.env.REACT_APP_BC_URL || '';
export const AUTHORIZATION_TOKEN_FOR_PROCTORU =
  process.env.REACT_APP_AUTHORIZATION_TOKEN_FOR_PROCTORU || '';

export const moodleBaseUrl = process.env.REACT_APP_MOODLE_BASE_URL || '';
export const proctorUExamPassword =
  process.env.REACT_APP_PROCTORU_EXAM_PASSWORD || '';
export const cloudfrontImageBaseUrl = process.env.REACT_APP_CDN_URL || '';
export const courseProvider = 'moodle';
export const partnerLogoBaseUrl =
  process.env.REACT_APP_PARTNER_LOGO_BASE_URL || 'https://s3.amazonaws.com';
export const acrobatiqMagentoURL =
  process.env.REACT_APP_MAGENTO_ACROBATIQ_URL || '';
export const acrobatiqLAURL = process.env.REACT_APP_LA_ACROBATIQ_URL || '';
export const acrobatiqAccessURL =
  process.env.REACT_APP_ACROBATIQ_ACCESS_URL || '';
export const acrobatiqIssuer = process.env.REACT_APP_ACROBATIQ_ISSUER_URL || '';
export const acrobatiqLaunchInitURL =
  process.env.REACT_APP_ACROBATIQ_LAUNCH_INIT_URL || '';
export const acrobatiqHostURL = process.env.REACT_APP_ACROBATIQ_HOST_URL || '';
export const acrobatiqClientId =
  process.env.REACT_APP_ACROBATIQ_CLIENT_ID || '';
export const acrobatiqDeploymentId =
  process.env.REACT_APP_ACROBATIQ_DEPLOYMENT_ID || '';
export const ga4MeasurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID || '';

export const Constants = {
  COURSE_COMPLETE: 'Course Complete',
  COURSE_PROGRESS: 'Course Progress',
  SEND_TRANSCRIPT: 'Send Transcript',
  ADD_A_COURSE: 'Add A Course',
  NAVIGATE_TO_HELP_CENTER: 'Go back to Help Center',
  HELP_CENTER_TITLE: 'Help Center',
  HELP_CENTER_SUB_TITLE: 'What can we help you with today?',
  COURSES: 'COURSES',
  BILLING_AND_ACCOUNT_MANAGEMENT: 'Billing and Account Management',
  TRANSCRIPTS: 'Transcript',
  CHECK_ICON_TIME: 5000,
};
export const Urls = {
  CONTACT_US: `${BC_URL}/contact-us/`,
  ABOUT_US: `${BC_URL}/about-us`,
  CAREERS: `${BC_URL}/careers/`,
  PRIVACY_POLICY: `${BC_URL}/privacy-policy/`,
  COURSES: `${BC_URL}/online-college-courses/most-popular/`,
  CAREER_PATHWAYS: `${BC_URL}`,
  HOW_IT_WORKS: `${BC_URL}/how-it-works/how-to-earn-college-credits/`,
  BLOG: `${BC_URL}/blog/`,
  SHOPPING_CART_URL: `${BC_URL}/cart.php`,
  FACEBOOK: 'http://www.facebook.com/Str8erLine',
  TWITTER: 'https://twitter.com/straighterline',
  YOUTUBE: 'https://www.youtube.com/straighterlinecourses',
  LINKEDIN: 'http://www.linkedin.com/company/straighterline',
  INSTAGRAM: 'https://www.instagram.com/straighterline/',
  ALL_COURSES: `${BC_URL}/online-college-courses/`,
  ADD_A_COURSE: `${BC_URL}/online-college-courses/most-popular/`,
  COLLEGE_FINDER: `${BC_URL}/colleges/`,
  CCEI: 'https://www.cceionline.com/',
  BILLING_PREFERENCE: `${BC_URL}/billing-preferences/`,
};

const courseUrls = {
  mostPopular: `${BC_URL}/online-college-courses/most-popular/`,
  business: `${BC_URL}/online-college-courses/business/`,
  collegePrep: `${BC_URL}/online-college-courses/online-college-prep-courses/`,
  education: `${BC_URL}/online-college-courses/education/`,
  english: `${BC_URL}/online-college-courses/english/`,
  healthScience: `${BC_URL}/online-college-courses/health-science/`,
  humanities: `${BC_URL}/online-college-courses/humanities/`,
  itTraining: `${BC_URL}/online-college-courses/technology/`,
  language: `${BC_URL}/online-college-courses/language/`,
  math: `${BC_URL}/online-college-courses/mathematics/`,
  science: `${BC_URL}/online-college-courses/sciences/`,
  socialScience: `${BC_URL}/online-college-courses/social-science/`,
};

export const courseCardIconsText = {
  topic_details: 'Topic Details',
  download_syllabus: 'Download Syllabus',
  schedule_proctoring: 'Schedule Proctoring',
};

export const CourseButton = {
  [CourseStatus.Complete]: 'Request Transcript',
  [CourseStatus.Progress]: 'Resume Course',
  [CourseStatus.NotStarted]: 'Start Course',
  takeCourseSurvey: 'Take Course Survey',
  purchaseCourse: 'Purchase Course',
  previewCourse: 'Preview Course',
};
export const FooterCenterPanel = {
  COPYRIGHT_YEAR: new Date().getFullYear(),
};

export const FooterLeftPanelList = [
  {
    label: 'ABOUT US',
    href: `${Urls.ABOUT_US}`,
    dataTestId: dataTestIds.footer.linkAboutUsText,
  },
  {
    label: 'CAREERS',
    href: `${Urls.CAREERS}`,
    dataTestId: dataTestIds.footer.linkCareerText,
  },
  {
    label: 'PRIVACY POLICY',
    href: `${Urls.PRIVACY_POLICY}`,
    dataTestId: dataTestIds.footer.linkPrivacyPolicyText,
  },
];

export const HeaderLeftPanelList = [
  {
    label: 'COURSES',
    href: `${Urls.COURSES}`,
    dataTestId: dataTestIds.header.mainMenuCourse,
    iconDataTestId: dataTestIds.header.mainMenuCourseIcon,
  },
  // { label: 'CAREER PATHWAYS', href: `${Urls.CAREER_PATHWAYS}` },
  // {
  //   label: 'HOW IT WORKS',
  //   href: `${Urls.HOW_IT_WORKS}`,
  // },
  // {
  //   label: 'BLOG',
  //   href: `${Urls.BLOG}`,
  // },
];

export type CourseOption = {
  label: string;
  href: string;
};

export const HeaderCourseOptions: CourseOption[] = [
  // {
  //   label: 'VIEW ALL COURSES',
  //   href: `${Urls.ADD_A_COURSE}`,
  // },
  // {
  //   label: 'COURSE PACKAGES',
  //   href: '/',
  // },
  // {
  //   label: 'COLLEGE FINDER',
  //   href: `${Urls.COLLEGE_FINDER}`,
  // },
  {
    label: 'Most Popular Courses',
    href: courseUrls.mostPopular,
  },
  {
    label: 'Business',
    href: courseUrls.business,
  },
  {
    label: 'College Prep',
    href: courseUrls.collegePrep,
  },
  {
    label: 'Education',
    href: courseUrls.education,
  },
  {
    label: 'English',
    href: courseUrls.english,
  },
  {
    label: 'Health Science',
    href: courseUrls.healthScience,
  },
  {
    label: 'Humanities',
    href: courseUrls.humanities,
  },
  {
    label: 'IT & Training',
    href: courseUrls.itTraining,
  },
  {
    label: 'Languages',
    href: courseUrls.language,
  },
  {
    label: 'Math',
    href: courseUrls.math,
  },
  {
    label: 'Science',
    href: courseUrls.science,
  },
  {
    label: 'Social Science',
    href: courseUrls.socialScience,
  },
];

export const HeaderRightPanelList = {
  DASHBOARD: 'DASHBOARD',
  SHOPPING_CART_URL: `${Urls.SHOPPING_CART_URL}`,
};

export const Routes = {
  home: '/',
  transcript: '/transcripts/create',
  transcriptHistory: '/transcripts',
  helpCenter: '/help-center',
  account: '/account',
  orientationTutorial: 'orientation-tutorials',
  supportRequest: 'support-request',
  academics: 'academics',
  courses: 'academics/courses',
  grading: 'academics/grading',
  exams: 'academics/exams',
  writtenAssignments: 'academics/written-assignments',
  labs: 'academics/labs',
  courseRetakes: 'academics/course-retakes',
  mtaTrainingCourses: 'academics/mta-training-courses',
  comptiaTrainingCourses: 'academics/comptia-training-courses',
  academicsCourses: '/help-center/academics/courses',
  academicsGrading: '/help-center/academics/grading',
  academicsExams: '/help-center/academics/exams',
  academicsWrittenAssignments: '/help-center/academics/written-assignments',
  academicsLabs: '/help-center/academics/labs',
  academicsCourseRetakes: '/help-center/academics/course-retakes',
  academicsMTATrainingCourses: '/help-center/academics/mta-training-courses',
  academicsComptiaTrainingCourses:
    '/help-center/academics/comptia-training-courses',
  proctoring: '/help-center/proctoring',
  etextbooks: '/help-center/etextbooks',
  accountManagement: '/help-center/account/account-management',
  billing: '/help-center/account/billing',
  popUpBlockers: '/help-center/account/pop-up-blockers/',
  creditTransfer: '/help-center/credit-transfer/credit-transfer',
  creditTransferaceTranscripts: '/help-center/credit-transfer/ace',
  systemCheck: 'system-check',
  studentHandbook: 'student-handbook',
  studentHandbookStudentCodeOfConduct:
    '/help-center/student-handbook/student-code-of-conduct',
  studentHandbookPrivacyPolicy: '/help-center/student-handbook/privacy-policy',
  studentHandbookAccessibility: '/help-center/student-handbook/accessibility',
  studentHandbookRefunds: '/help-center/student-handbook/refunds',
  studentHandbookTermsOfUse: '/help-center/student-handbook/terms-of-use',
  studentHandbookPoliciesAndGuidlines:
    '/help-center/student-handbook/assessment-polices-guidlines',
  studentHandbookCourseDesign: '/help-center/student-handbook/course-design',
  accountSettings: '/account/account-settings',
  profileDetails: '/account/profile-details',
  billinPreference: '/account/billing-preferences',
  LTI13Handler: '/lti/launch13/handler',
};

export const APIs = {
  getMasterData: '/v1/appdata',
  getCourses: '/v1/learners/%s/courses',
  addTrialCourses: '/v1/learners/%s1/enrollLearner',
  getAssignments: '/v1/learners/%s1/courses/%s2/assignments',
  getLMSProviderDetails: '/v1/learners/%s1/courses/%s2/lms',
  getProfile: '/v1/learners/%s',
  getInstitutes: '/v1/partner/institutions',
  getTargetInstitute: '/v1/partner/institutions/%s',
  uploadImage: '/v1/learners/%s/profile/upload',
  proctoredExamUrl: '/api/addBlueBirdExam/',
  courseSurvey: '/v1/learners/%s/survey',
  purchaseRecommendation: '/v1/recommendations',
  transcript: '/v1/learners/%s/transcripts',
  getProvidersJWTToken: '/v1/providers/token',
};

export const CouseModalConstants = {
  proctoring: {
    title: 'Schedule Proctoring',
    descriptionn:
      'This course requires proctoring for the final exam. Please schedule your exam 72 hours in advance. Visit our Help Center for more details.',
  },
  buttons: {
    sendTranscript: 'Send transcript',
    resumeCourse: 'Resume Course',
  },
  progress: {
    final: 'Final',
  },
  ahpMessage:
    'You are unable to request a transcript due to an Academic Hold on this course.',
};

export const CourseCardConstants = {
  upNext: 'UP NEXT...',
  expiredText: 'Expired on %s',
  expiredMembership: {
    buttons: {
      renewMembership: 'Renew membership',
    },
    header: 'Renew membership',
    subHeader: 'Your subscription has expired.',
    message:
      'Your courses are currently unavailable. Please renew your membership to regain access.',
    membershipLink: Urls.BILLING_PREFERENCE,
  },
  pending: {
    contentText: 'Enrollment is <strong>pending.</strong>',
    actionText:
      'Pending enrollments are usually activated in <strong>2-3 minutes</strong>, but in some cases it can take up to <strong>30 minutes</strong>. Refresh page to update enrollment status.',
  },
};

export const tutorialsStepsCardConstants = {
  steps: 'Steps',
  startTutorial: 'Start Tutorial',
};

export const footerHelpCenter = {
  header: 'Questions?',
  contactRequest:
    'Contact our Student Advisors at <a href="tel:8443977501">(844) 397-7501</a>, Live Chat or <a href="/help-center/support-request">create a Support Request</a>.',
  timings: 'Student Support Hours: Monday - Friday, 9:00am-5:00pm EST.',
};

export const emptyString = '';

export const localStorageConstants = {
  token: 'token',
  accessToken: 'accessToken',
  profile: 'profile',
  firstInDayMasterData: 'firstInDayMasterData',
  firstInDayInstitutes: 'firstInDayInstitutes',
  masterData: 'masterData',
  institutes: 'institutes',
};

export const sessionStorageConstants = {
  isSupportFormSubmitted: 'isSupportFormSubmitted',
};

export const onBoardingConstants = {
  steps: ['PERSONAL INFORMATION', 'ABOUT YOURSELF', 'START LEARNING'],
  personalInfo: {
    header: 'Personal Information',
    dob: 'Date of Birth',
    studentFistName: 'Student First Name',
    studentLastName: 'Student Last Name',
    emailAddress: 'Email Address',
    phoneNumber: 'Phone Number',
    maxLengths: {
      phone: 10,
    },
    mailingAddress: {
      header: 'Mailing Address',
      address: 'Address',
      addressLine2: 'Address Line 2 (Optional)',
      city: 'City',
      state: 'State',
      stateRegion: 'State Region',
      country: 'Country',
      zipPostalCode: 'Zip/Postal Code',
      postalCode: 'Postal Code',
      maxLengths: {
        address: 40,
        city: 35,
        stateRegion: 35,
        zip: 5,
        zipNonUS: 20,
      },
      minLengths: {
        zipNonUS: 5,
      },
    },
    fields: {
      address: 'address',
      addressLine2: 'addressLine2',
      city: 'city',
      zip: 'zip',
      state: 'state',
      country: 'country',
    },
  },
  aboutYourself: {
    header: 'Tell us a little more about yourself',
  },
  errors: {
    invalidDob:
      'Users in the system must be at least 13 years old, and date of birth selection can not be a future date.',
    invalidDate: 'Date of birth should be in MM/DD/YYYY format',
    invalidPhoneNumber: 'Phone number must be 10 digits',
    invalidZip: 'Zip code must be 5 digits',
    invalidZipNonUS: 'Zip code must be between 5 to 20 digits',
  },
  profileDetails: {
    header: 'Profile Details',
    aboutYou: 'About You',
    targetCollegeLabel: `What’s your next step after StraighterLine?`,
    targetCollege: `Target College`,
    undecidedCollege: `I'm still exploring college options / not applicable to my current goals`,
    areaStudyLabel: `What field or career are you most interested in pursuing?`,
    areaStudy: `Area of Study`,
    highestEducationLabel: `Which of the following best describes you?`,
    highestEducation: `Highest Level of Education Completed`,
    referenceLabel: `How did you hear about StraighterLine?`,
    reference: `Referred By`,
    fields: {
      courseName: 'courseName',
      targetCollege: 'targetCollege',
      highestEducation: 'highestEducation',
      referredBy: 'referredBy',
    },
  },

  status: {
    notStarted: 'notStarted',
    personalInfoCompleted: 'personalInfoCompleted',
    completed: 'completed',
  },

  profileUpdationState: {
    pending: 'Pending',
    error: 'Error',
    success: 'Success',
  },
};

export const US_PHONE_FORMAT_REGEX = /(\d{3})(\d{3})(\d{4})/;
export const US_PHONE_REFORMAT_REGEX = /[^\d]/g;

export const minAge = 13;

export const EMPTY_STRING = '';
export const profileHeaderConstants = {
  renewMembership: `Your subscription has expired. <a href='${BC_URL}/cart.php?action=buy&sku=%s&source=buy_button' >Renew now</a> to continue your StraighterLine membership.`,
  membershipCurrent: 'Membership current through:',
  undecidedCollege: 'Undecided College/University',
};

export const TARGET_COLLEGE_UNDECIDED = 'Undecided';

export interface HelpfulLinkType {
  text: string;
  subText: string;
  link: string;
  isExternalLink: boolean;
  isBigCommerceLink: boolean;
}

export const dashboardRightPanel = {
  myTargetSchool: 'MY TARGET SCHOOL IS',
  hepfulLinks: {
    header: 'Helpful Links',
    linkData: [
      {
        text: 'Prepare for Proctored Exam',
        subText: 'Includes Equipment Test',
        link: '/help-center/proctoring',
        isExternalLink: false,
        isBigCommerceLink: false,
      },
      {
        text: 'Buy Another Course',
        subText: '5 min to checkout',
        link: `${BC_URL}/online-college-courses/most-popular/`,
        isExternalLink: false,
        isBigCommerceLink: true,
      },
      {
        text: 'Learn More About Grading',
        subText: '5-10 min read',
        link: '/help-center/academics/grading',
        isExternalLink: false,
        isBigCommerceLink: false,
      },
    ],
  },
  purchaseCourseToday: {
    header: 'Purchase a course today',
    button: {
      text: 'Purchase a course now',
      link: Urls.ALL_COURSES,
    },
  },
  courseRecommendation: {
    header: 'Course Recommendation',
    button: 'Learn More',
    maxSteps: 3,
  },
};

export const ProfileDetailsConstants = {
  cancel: 'Cancel',
};

export const AccountSettingsConstants = {
  yourPhoto: 'Your Photo',
  accountSettings: 'Account Settings',
  profileDetails: 'Profile Details',
  billingPreferences: 'Billing Preferences',
  studentInfo: 'Student Information',
  studentInfoSubHeading:
    'Please contact your Student Advisor to update name or email',
  changePassword: 'Update Password',
  cancel: 'Cancel',
  updateBtn: 'UPDATE SETTINGS',
  demoPassword: '@Straighterline123',
  passwordTypes: {
    password: 'password',
    text: 'text',
  },
  passwordLabels: {
    current: 'Current Password',
    old: 'Old Password',
    new: 'New Password',
    confirm: 'Confirm Password',
  },
  passwordMessage: {
    success: 'Password changed successfully',
    mismatch: 'New password and confirm password does not match',
    mustMatch: 'Passwords must match',
  },
};

export const onboardingDialogConstants = {
  title: 'WELCOME %s',

  welcome: {
    contentOne: 'We are very excited to start this journey with you.',
    contentTwo:
      "Now that you've created an account, <strong>we just need a few more details from you to set up your StraighterLine Student Profile</strong>. Please take a moment to provide us with this additional information.",
    button: 'COMPLETE YOUR PROFILE',
  },

  allSet: {
    title: 'ALL SET!',
    contentOne: 'You are ready to begin this journey.',
    contentTwo:
      '<strong>Thank you for completing your profile</strong> and choosing to embark on your educational journey with StraighterLine!<br/>We are very excited to be a part of your learning experience and look forward to supporting you every step of the way as you achieve  your academic and career goals.',
    button: 'GO TO DASHBOARD',
  },
  buttonBack: 'BACK',
  buttonNext: 'NEXT',
};

export const addTrialCoursesDialogConstants = {
  title: 'Add trial courses to your dashboard',
  subtitle: 'Select from the available courses to preview course material.',
  buttonAddCourses: 'ADD COURSES',
};

export interface Month {
  id: string;
  name: string;
}

export const required = '* Required';
export const invalid = '* Invalid';

export const US_COUNTRY_ID = '16459eb8-e61f-4faa-8d9d-9df253a2b285';
export const US_COUNTRY_CODE = 'US';

export const passwordValidations = {
  title: 'Password must contain:',
  rules: {
    minChar: { title: 'A minimum of 8 characters', value: 8 },
    lowerCase: { title: 'At least 1 lowercase character', value: null },
    upperCase: { title: 'At least 1 uppercase character', value: null },
    number: { title: 'At least 1 number', value: /\d/ },
    specialChar: {
      title: 'At least 1 special character',
      value: /[\^°<>#*~!@"§$%?®©¶()&]+/,
    },
  },
};

export const profilePic = {
  jpgFile: 'image/jpeg',
  pngFile: 'image/png',
  fileType: 'file',
  uploadProfilePic: 'Upload Photo',
  fileSizeToKB: 1024,
  fileSize: 500,
  imageTypeSpecification: 'You can upload a JPEG or PNG file.',
  imageSizeSpecification: 'Image file size limit is 500KB.',
  invalidFile: 'Invalid file type or size',
};

export const takeSurveyUrl = TAKE_SURVEY_URL;

export const supportRequests = {
  action: SUPPORT_REQUESTS_ACTION,
  header: 'Support Requests',
  subHeader:
    'Need help? Complete this form, and our support team will get back to you shortly.',
  orgId: SUPPORT_REQUESTS_ORG_ID,
  lastNameId: SUPPORT_REQUESTS_LAST_NAME_ID,
  redirectUrl: window.location.href,
  transcriptRequestUrl: TRANSCRIPT_REQUEST_URL,
  recordType: Record_Type,
};

export const billingPreferences = {
  header: 'Billing Settings',
  subHeader: 'Cancel Membership',
  pageDescription: `To enable continuous course access, StraighterLine Memberships automatically renew each month.<br/> To cancel your membership, please fill in the form below, and our support team will email you cancellation confirmation. You will continue to have access to your courses for the remaining duration of your membership period.`,
};

export const GlobalConstants = {
  SOMETHING_WENT_WRONG: 'Something went wrong. Please try again later',
};

export const toastMessages = {
  accountSettings: {
    success: 'Profile settings updated successfully',
    error:
      'There was an issue updating your settings. Please try again later or contact support.',
  },
  supportRequests: {
    success:
      'Thank you for your submission. We have received your Support Request and will be reviewing and responding via email as soon as possible.',
  },
  invalidTokenMessage:
    'There was an invalid token error during your session. Please try logging in again.',
};
export const completeCourseImageBannerUrl = `${cloudfrontImageBaseUrl}/courses/%s1/assets/images/card/%s2.jpg`;

export const screenBreakpoints = {
  smallPhone: '320px',
  phone: '480px',
  iPad: '768px',
  laptop: '1024px',
  laptopLarge: '1200px',
  desktop: '1400px',
  desktopLarge: '1640px',
};

export const routePathConstants = {
  dashboard: 'dashboard',
  helpCenter: 'helpCenter',
  accountSettings: 'accountSettings',
  profileDetails: 'profileDetails',
  billingPreference: 'billingPreference',
  transcriptHistory: 'transcriptHistory',
  transcript: 'transcript',
};

const pageTitleConstants: any = {
  dashboard: 'Student Dashboard | StraighterLine',
  helpCenter: 'Help Center | StraighterLine',
  accountSettings: 'Account Setting | StraighterLine',
  profileDetails: 'Profile Details | StraighterLine',
  billingPreference: 'Billing Preference | StraighterLine',
  transcriptHistory: 'Transcript History | StraighterLine',
  transcript: 'Transcript | StraighterLine',
};

export const getPageTitle = (key: string) => {
  return pageTitleConstants[key];
};

export const FooterRightPanel = [
  {
    label: 'CONTACT US',
    href: `${Routes.helpCenter}/${Routes.supportRequest}`,
    dataTestId: dataTestIds.footer.linkContactUsText,
  },
  // {
  //   label: 'LIVE CHAT',
  //   href: 'javascript:document.getElementsByClassName("helpButtonEnabled")[0].click()',
  // },
];

export const helpCenterImagesUrl = {
  BackOfCourseCard_Proctoring_Schedule: `${CDN_URL}/helpcenter/assets/images/proctoring/BackOfCourseCard_Proctoring_Schedule.png`,
  CourseCard_Proctoring_Schedule: `${CDN_URL}/helpcenter/assets/images/proctoring/CourseCard_Proctoring_Schedule.png`,
  Proctoring_Start: `${CDN_URL}/helpcenter/assets/images/proctoring/Proctoring_Start.png`,
  Membership_Billing_Preferences: `${CDN_URL}/helpcenter/assets/images/account/Membership_Billing_Preferences.png`,
  Membership_Cancel: `${CDN_URL}/helpcenter/assets/images/account/Membership_Cancel.png`,
  Membership_Confirmation: `${CDN_URL}/helpcenter/assets/images/account/Membership_Confirmation.jpg`,
  Course_Assignments_Submit: `${CDN_URL}/helpcenter/assets/images/course/Course_Assignments_Submit.png`,
  Course_Resume: `${CDN_URL}/helpcenter/assets/images/course/Course_Resume.png`,
};

export const transcriptFeeAmount = '$10';

export const getTranscriptInstructions = (isFeeFeatureEnabled: boolean) => {
  if (isFeeFeatureEnabled) {
    return requestTranscipt.transcrriptPoints.points;
  } else {
    return requestTranscipt.transcrriptPoints.points.slice(0, 3);
  }
};

export const requestTranscipt = {
  headers: {
    sendTranscript: 'Request new transcript',
    personalInfo: 'Confirm your personal information for transcript',
    selectDestination: 'Select a transcript destination',
    deliveryDestination: 'Provide delivery details for destination',
    transcripts: 'Transcripts',
    transcriptRequests: 'Transcript Requests',
  },
  viewHistoryMessage: `For more information view <a href="/transcripts" >Transcript History</a>`,
  userInfo: {
    name: 'Name:',
    email: 'Email:',
    dob: 'DOB:',
  },
  edit: 'Edit',
  transcriptDestination: 'Unofficial copy only or select destination',
  transcriptDestinationMobile: 'Unofficial copy or...',
  mailTranscript: 'I’d like to mail my transcript ',
  emailTranscript: 'I’d like to email my transcript  ',
  emailUnofficial: 'Email me an unofficial copy of my transcript',
  destinationEmailAddress: 'Destination Email Address',
  mailingAddress: {
    header: 'Mailing Address:',
    maxLengths: {
      address: 40,
      city: 35,
      zip: 5,
      zipNonUS: 20,
    },
    minLengths: {
      zipNonUS: 5,
    },
  },
  fields: {
    emailDestination: 'emailDestination',
    emailTranscript: 'emailTranscript',
    mailTranscript: 'mailTranscript',
  },
  buttons: {
    submitRequest: 'Submit Transcript Request',
    submitAnotherRequest: 'Submit Another Transcript',
    newTranscript: 'Request New Transcript',
    viewHistory: 'View transcript history',
    close: 'Close',
    update: 'Update',
    cancel: 'Cancel',
  },
  errors: {
    invalidEmail: 'Email Address is invalid',
    deliveryDestination:
      'You must select at least one destination to submit a transcript request.',
    nonPartnerDeliveryDestination: `You've chosen a non-partner destination. Please, provide delivery details`,
    newDeliveryDestination: `You've chosen a custom destination. Please, provide delivery details`,
  },
  required: 'Required',
  noTranscript: 'You have no courses available for transcript.',
  eligibilityMessage:
    'To view requirements for course eligibility, visit the  Transcript History Page.',
  success: {
    header: 'Success!',
    subHeader: 'Your transcript request has been submitted.',
    message: `Please allow at least 24 hours for processing. You can check the status of your transcript request at any time by viewing your Transcript History Page.`,
  },
  loader: 'We are processing your transcript request.',
  partnerCollege: {
    header: 'Great choice!',
    message:
      'is one of our partners, so we will handle the rest of the details for you.',
  },
  status: {
    processing: {
      value: 'processing',
      bgcolor: themeColors.white,
      textColor: themeColors.black,
      border: `2px solid ${themeColors.black}`,
      label: 'Processing',
    },
    new: {
      value: 'new',
      bgcolor: themeColors.blueLight,
      textColor: themeColors.white,
      border: 'none',
      label: 'in progress',
    },
    progress: {
      value: 'in progress',
      bgcolor: themeColors.blueLight,
      textColor: themeColors.white,
      border: 'none',
      label: 'in progress',
    },
    approved: {
      value: 'approved',
      bgcolor: themeColors.secondary,
      textColor: themeColors.white,
      border: 'none',
      label: 'approved',
    },
    declined: {
      value: 'declined',
      bgcolor: themeColors.warningDark,
      textColor: themeColors.white,
      border: 'none',
      label: 'declined',
    },
    hold: {
      value: 'hold',
      bgcolor: themeColors.yellow,
      textColor: themeColors.black,
      border: 'none',
      label: 'on hold',
    },
    deleted: {
      value: 'deleted',
      bgcolor: themeColors.white,
      textColor: themeColors.black,
      border: `2px solid ${themeColors.black}`,
      label: 'deleted',
    },
  },
  transcrriptPoints: {
    header:
      'In order for your course to be eligible for transfer, it must meet the following conditions:',
    points: [
      '<i>Your course must be marked as “Complete” in our systems. Even if you have completed all your required assignments, some courses require a grader to review the final assignments to mark the course as complete.</i>',
      '<i>Your final grade must be greater than a 70%</i>',
      '<i>There cannot be an <a href="/help-center/credit-transfer/credit-transfer#hc_ct_academic_holds" >academic hold</a> on the course</i>',
      `<i>Any required fees have been processed. </i>StraighterLine does not charge for transcripts sent to participating partner schools or via ACE (American Council on Education) or unofficial transcripts sent to the student's email address. There is a ${transcriptFeeAmount} per transcript fee for any other destination.`,
    ],
  },
  viewCourses: 'View eligible courses',
  minGradeValue: 69.45,
  updated: 'Updated',
  courseTableHeader: [
    {
      name: 'COURSE',
      width: '75%',
    },
    {
      name: 'COMPLETION DATE',
      width: '15%',
    },
    {
      name: 'FINAL GRADE',
      width: '10%',
    },
  ],
  transcriptFeeMessage: `* You've chosen a non-partner school for your transcript. Sending a transcript to this destination will incur a ${transcriptFeeAmount} fee.`,
  unofficial: 'Unofficial',
};

export const features = {
  customInstitute: 'customInstituteFlag',
  transcriptFee: 'transcriptFeeFlag',
};

export const API_STATE = {
  pending: 'Pending',
  error: 'Error',
  success: 'Success',
};

export const partnerCollegeLogoUrl = `${partnerLogoBaseUrl}/straighterline-web/images/partner-logos/%s1/%s1-225.png`;

export const LTIRedirectUrls = {
  MagentoRedirectUrl: acrobatiqMagentoURL,
  LARedirectUrl: acrobatiqLAURL,
};

export const AcrobatiqParams = {
  loginHint: 'login_hint',
  clientId: 'client_id',
  iss: 'iss',
  launchInitUrl: 'launch_init_url',
  ltiDeploymentId: 'lti_deployment_id',
  ltiMessageHint: 'lti_message_hint',
  targetLinkUrl: 'target_link_uri',
  nonce: 'nonce',
  state: 'state',
  scope: 'scope',
};

export const FreeTrialCourses = [
  {
    id: 'ENG101.freetrial',
    name: 'English Composition I',
    image: `${cloudfrontImageBaseUrl}/courses/ENG101/assets/images/modal/ENG101.png`,
    purchaseLink: `${BC_URL}/cart.php?action=buy&sku=NB-01-ENG-100-001-00&source=buy_button`,
  },
  {
    id: 'ENG102.freetrial',
    name: 'English Composition II',
    image: `${cloudfrontImageBaseUrl}/courses/ENG102/assets/images/modal/ENG102.png`,
    purchaseLink: `${BC_URL}/cart.php?action=buy&sku=NB-01-ENG-100-002-00&source=buy_button`,
  },
  {
    id: 'COM101.freetrial',
    name: 'Introduction to Communication',
    image: `${cloudfrontImageBaseUrl}/courses/COM101/assets/images/modal/COM101.png`,
    purchaseLink: `${BC_URL}/cart.php?action=buy&sku=NB-01-HUM-100-006-00&source=buy_button`,
  },
  {
    id: 'BUS105.freetrial',
    name: 'Business Communication',
    image: `${cloudfrontImageBaseUrl}/courses/BUS105/assets/images/modal/BUS105.png`,
    purchaseLink: `${BC_URL}/cart.php?action=buy&sku=NB-01-BUS-100-006-00&source=buy_button`,
  },
];

export const htmlEvents = {
  arrowDown: 'ArrowDown',
  enter: 'Enter',
};

export { dataTestIds };
